/*============================ 
 HEADER
 =============================*/
const header = document.querySelector(".header");

const scrollUp = "header--show";
const scrollDown = "header--hide";
let lastScroll = 0;

window.addEventListener("scroll", () => {
  const currentScroll = window.pageYOffset;
  if (currentScroll == 0 || currentScroll == -1) {
    header.classList.remove(scrollUp);
    header.classList.remove(scrollDown);
    if (window.location.pathname == "/") {
      header.classList.remove("header--light");
    }
    return;
  }

  if (currentScroll > lastScroll && !header.classList.contains(scrollDown)) {
    // down
    header.classList.remove(scrollUp);
    header.classList.add(scrollDown);
  } else if (
    currentScroll < lastScroll &&
    header.classList.contains(scrollDown)
  ) {
    // up
    header.classList.remove(scrollDown);
    header.classList.add(scrollUp);
    header.classList.add("header--light");
  }
  lastScroll = currentScroll;
});

/*=======================================
NAV
========================================*/
const app = (() => {
  let body;
  let menu;
  let menuItems;

  const init = () => {
    body = document.querySelector("body");
    menu = document.querySelector(".menu-icon");
    menuItems = document.querySelectorAll(".nav__list-item");

    applyListeners();
  };

  const applyListeners = () => {
    menu.addEventListener("click", () => toggleClass(body, "nav-active"));
  };

  const toggleClass = (element, stringClass) => {
    if (element.classList.contains(stringClass))
      element.classList.remove(stringClass);
    else element.classList.add(stringClass);
  };

  init();
})();
