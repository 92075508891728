import Swiper from "swiper/js/swiper";

function swiper($selector, $options) {
  return new Swiper($selector, $options);
}

swiper(".hero__sliderContainer", {
  slidersPerView: 1,
  effect: "fade",
  fadeEffect: { crossFade: true },
  loop: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  pagination: {
    el: ".hero__sliderPagination",
    dynamicBullets: true,
    clickable: true
  }
});

/* Product single */
var galleryThumbs = new Swiper(".gallery-thumbs", {
  spaceBetween: 10,
  slidesPerView: 3,
  loop: true,
  freeMode: true,
  loopedSlides: 5, //looped slides should be the same
  watchSlidesVisibility: true,
  watchSlidesProgress: true
});
var galleryTop = new Swiper(".gallery-top", {
  spaceBetween: 10,
  loop: true,
  loopedSlides: 5, //looped slides should be the same
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
  zoom: {
    maxRatio: 5
  },
  thumbs: {
    swiper: galleryThumbs
  }
});

/* RECOMMENDATION */

swiper(".recommendation__swiper", {
  slidesPerView: 1,
  slidesPerGroup: 1,
  initialSlide: 1,
  spaceBetween: 30,
  speed: 600,
  loop: true,
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 40,
      initialSlide: 4
    },
    1024: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 50,
      initialSlide: 4
    },
    1200: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 40,
      initialSlide: 4
    }
  }
});


swiper(".logo-slider", {
  slidesPerView: 2,
  spaceBetween: 30,
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true
  },
  breakpoints: {
    768: {
      slidesPerView: 3,
      spaceBetween: 30
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 30
    },
    1200: {
      slidesPerView: 5,
      spaceBetween: 30
    }
  }
});